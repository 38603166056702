<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="600"
            width="600"
            class="pa-5"
          >
            <h3 class="text-h5">
              Edit Profile
            </h3>

            <v-alert
              v-if="errors.non_field_errors"
              dense
              border="left"
              type="warning"
            >
              {{ errors.non_field_errors[0] }}
            </v-alert>

            <form>
              <v-text-field
                v-model="formFields.first_name"
                label="First name"
                :error-messages="errors.first_name"
                append-icon="mdi-pencil"
                required
              />

              <v-text-field
                v-model="formFields.last_name"
                label="Last name"
                :error-messages="errors.last_name"
                append-icon="mdi-pencil"
                required
              />

              <v-text-field
                v-model="formFields.email"
                label="Email"
                :error-messages="errors.email"
                append-icon="mdi-pencil"
                required
              />

              <v-text-field
                v-model="formFields.phone"
                label="Phone number"
                type="tel"
                :error-messages="errors.phone"
                append-icon="mdi-pencil"
                required
              />

              <v-checkbox
                v-model="formFields.change_password"
                label="Change password"
              />

              <v-expand-transition>
                <div v-if="formFields.change_password">
                  <v-text-field
                    v-model="formFields.current_password"
                    label="Current password"
                    :error-messages="errors.current_password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :required="formFields.change_password"
                    @click:append="showPassword = !showPassword"
                  />

                  <v-text-field
                    v-model="formFields.new_password1"
                    label="New password"
                    :error-messages="errors.new_password1"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :required="formFields.change_password"
                    @click:append="showPassword = !showPassword"
                  />

                  <v-text-field
                    v-model="formFields.new_password2"
                    label="Confirm new password"
                    :error-messages="errors.new_password2"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :required="formFields.change_password"
                    @click:append="showPassword = !showPassword"
                  />
                </div>
              </v-expand-transition>

              <div class="text-right">
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="submitting"
                  class="mt-2"
                  @click.prevent="submit"
                >
                  Save
                  <v-progress-circular
                    v-if="submitting"
                    size="20"
                    width="2"
                    indeterminate
                    class="ml-2"
                  />
                </v-btn>
              </div>
            </form>

            <v-snackbar
              v-model="success"
              :timeout="2000"
              color="success"
            >
              <div class="text-center">
                Your changes have been saved.
              </div>
            </v-snackbar>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'EditProfileView',
  data() {
    return {
      submitting: false,
      errors: {},
      success: false,
      formFields: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        change_password: false,
        current_password: '',
        new_password1: '',
        new_password2: '',
      },
      showPassword: false,
    };
  },
  metaInfo: {
    title: 'Edit Profile',
  },
  created() {
    this.reset();
  },
  methods: {
    async submit() {
      this.errors = {};
      this.submitting = true;

      const postData = JSON.parse(JSON.stringify(this.formFields));

      if (!this.formFields.change_password) {
        delete postData.current_password;
        delete postData.new_password1;
        delete postData.new_password2;
      }

      const responseData = await this.api({
        url: 'users/edit_profile/',
        method: 'PATCH',
        json: postData,
      });

      this.submitting = false;

      if (responseData.status === 200) {
        this.$store.commit('userData', responseData.body);
        this.success = true;
        this.reset();
      } else if (responseData.status === 400) {
        this.errors = responseData.body;
      } else {
        this.errors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    reset() {
      this.formFields = {
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        email: this.userData.email,
        phone: this.userData.phone,
        change_password: false,
        current_password: '',
        new_password1: '',
        new_password2: '',
      };
    },
  },
};
</script>
